import React from 'react'
import styled from 'styled-components'
import { Typography } from './Typography'
import { rgba } from './ColorFunctions'

export function Checkbox({
  checked,
  onChange,
  description,
  name,
  gridColumn,
  size = 24,
  noPaddingLeft,
  color,
}) {
  return (
    <Container gridColumn={gridColumn} color={color}>
      <input
        type="checkbox"
        className="checkbox"
        id={`checkbox-${name}`}
        name={`checkbox-${name}`}
        checked={checked}
        onChange={onChange}
      />
      <Typography
        variant="label"
        htmlFor={`checkbox-${name}`}
        className={`ctx ${noPaddingLeft ? 'noPaddingLeft' : ''}`}
      >
        <span
          style={{
            width: size,
            height: size,
            borderRadius: 4 * (size / 20),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg width={size * 0.75} height={size * 0.667}>
            <use xlinkHref="#check">
              <symbol id="check" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1" />
              </symbol>
            </use>
          </svg>
        </span>
        <span>{description}</span>
      </Typography>
    </Container>
  )
}

const Container = styled.div`
  grid-column: ${({ gridColumn }) => gridColumn};
  margin: auto 0;
  .ctx {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.2s ease;
    border-radius: 6px;
    padding: 0.5rem;
    color: ${({ color, theme }) => {
      if (color) return color
      else return theme.color.labelText
    }};
    &:hover {
      background-color: ${({ color = '#fff', theme }) => rgba('#fff', 0.15)};
    }
    &:not(:last-child) {
      margin-right: 6px;
    }
    span {
      float: left;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
      &:first-child {
        position: relative;
        border-style: solid;
        border-color: ${({ theme }) => theme.color.purple200};
        transition: all 0.2s ease;
        border-width: 3px;
        svg {
          fill: none;
          stroke: ${({ theme }) => theme.primary};
          stroke-linecap: round;
          stroke-linejoin: round;
          transition: all 0.3s ease;
          transition-delay: 0.1s;
          transform: translate3d(0, 0, 0);
          stroke-width: 1.8;
          stroke-dasharray: 18px;
          stroke-dashoffset: 18px;
        }
      }
      &:last-child {
        padding-left: 8px;
        font-size: 0.9rem;

        @media (min-width: 768px) {
          font-size: 1rem;
        }
      }
    }
    &:hover span:first-child {
      /* border-color: white; */
      border-color: ${({ color, theme }) => {
        if (color) return color
        else return 'white'
      }};
    }
    &.noPaddingLeft {
      padding-left: 0;
      &:hover {
        background-color: ${({ theme }) => rgba('#fff', 0.0)};
      }
    }
  }

  .checkbox {
    display: none;
  }

  .checkbox:checked + .ctx {
    span {
      &:first-child {
        background: white;
        border-color: ${({ color }) => {
          if (color) return color
          else return 'white'
        }};
        animation: wave 0.4s ease;
        svg {
          stroke-dashoffset: 0;
        }
      }
    }
  }
  @keyframes wave {
    50% {
      transform: scale(0.8);
    }
  }
`

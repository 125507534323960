import { useEffect, useState } from 'react'
import { fetchGetOptions } from './fetchHelpers'

export async function fetchProvider() {
  try {
    const response = await fetch(
      process.env.GATSBY_GHOSTBUILDER_MERCHANT_API,
      fetchGetOptions
    )
    const body = await response.json()
    return body
  } catch (err) {
    console.log(err.message)
  }
}

export function useProviders() {
  const [providers, setProviders] = useState([
    { label: 'Günstigster', value: 'cheapest' },
  ])

  useEffect(() => {
    async function fetchProvider() {
      try {
        const response = await fetch(
          process.env.GATSBY_GHOSTBUILDER_MERCHANT_API,
          fetchGetOptions
        )
        const body = await response.json()
        setProviders((prev) => [...prev, ...new Set(body)])
      } catch (err) {
        console.log(err.message)
      }
    }
    if (providers.length < 2) {
      fetchProvider()
    }
  }, [providers.length])

  return [providers, setProviders]
}

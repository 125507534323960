import React from 'react'
import styled from 'styled-components'
import useMedia from 'use-media'
import { Link, LogoText, Typography } from '../utils'

export function Footer({ style, wrapperStyle }) {
  const isDesktop = useMedia({ minWidth: 768 })
  return (
    <Container style={style}>
      <Wrapper style={wrapperStyle}>
        <Hero>
          <LogoLink to="/">
            <LogoText height={64} width="260" color="#2A1E4D" />
          </LogoLink>
          <Typography>
            ghostbuilder ist dein kostenloser PC-Konfigurator,
            {isDesktop ? <wbr /> : <br />}
            der mit Algorithmen in Echtzeit einen Computer
            {isDesktop ? <wbr /> : <br />}
            zusammenstellt &amp; die günstigsten Preise findet.
          </Typography>
        </Hero>
        <SocialList>
          <Link
            color="currentColor"
            font="secondary"
            to="https://www.tiktok.com/@ghostbuilder.io"
            target="_blank"
            rel="noopener"
          >
            TikTok
          </Link>
          <Link
            color="currentColor"
            font="secondary"
            to="https://discord.gg/V8v8v9zpJQ"
            target="_blank"
            rel="noopener"
          >
            Discord
          </Link>
          <Link
            color="currentColor"
            font="secondary"
            to="https://www.instagram.com/ghostbuilder.io/"
            target="_blank"
            rel="noopener"
          >
            Instagram
          </Link>
          <Link
            color="currentColor"
            font="secondary"
            to="https://twitter.com/ghostbuilderio"
            target="_blank"
            rel="noopener"
          >
            Twitter
          </Link>
          <Link
            color="currentColor"
            font="secondary"
            to="https://www.facebook.com/ghostbuilderIO/"
            target="_blank"
            rel="noopener"
          >
            Facebook
          </Link>
        </SocialList>
        <LinkContainer>
          <LinkList>
            <Link color="currentColor" font="secondary" to="/">
              Startseite
            </Link>
            <Link color="currentColor" font="secondary" to="/builder/">
              Builder
            </Link>
            <Link color="currentColor" font="secondary" to="/blog/">
              Blog und Anleitungen
            </Link>
          </LinkList>
          <LinkList>
            <Link color="currentColor" font="secondary" to="/about/">
              Über uns
            </Link>
            <Link color="currentColor" font="secondary" to="/impressum/">
              Impressum
            </Link>
            <Link color="currentColor" font="secondary" to="/privacy-policy/">
              Datenschutz
              <wbr />
              erklärung
            </Link>
          </LinkList>
        </LinkContainer>
        <Copyright>
          Copyright © {new Date().getFullYear()} ghostbuilder.
        </Copyright>
      </Wrapper>
    </Container>
  )
}

const Container = styled.footer`
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  background-color: white;
  color: ${({ theme }) => theme.primary};
  width: 100%;
  padding: 3rem 1rem;
  @media (min-width: 768px) {
    padding: 6rem 1.5rem 3rem;
  }
`

const Wrapper = styled.nav`
  max-width: 1024px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: start;
  gap: 2rem 1rem;
  /* grid-auto-flow: row; */
  @media (min-width: 768px) {
    grid-template-columns: 5fr 6fr;
    gap: 4rem 1rem;
  }
`

const Hero = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 5fr 6fr;
    justify-content: start;
    font-size: 20px;
    gap: 2rem 1rem;
  }
  @media (min-width: 1024px) {
    font-size: 22px;
  }
`

const LinkContainer = styled.div`
  grid-column: span 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (min-width: 425px) {
    justify-content: start;
    gap: 2rem;
  }
  @media (min-width: 768px) {
    grid-column: 1;
    grid-row: 2;
  }
`

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    padding: 0.5rem;
    width: fit-content;
    color: ${({ theme }) => theme.primary};
  }
  @media (min-width: 768px) {
    align-items: flex-start;
  }
`

const SocialList = styled(LinkList)`
  grid-column: span 2;
  flex-direction: row;
  flex-flow: wrap;
  gap: 0 1rem;
  height: fit-content;
  @media (min-width: 768px) {
    grid-column: 2;
    gap: 0 0.75rem;
    align-content: flex-start;
  }
`
const Copyright = styled(Typography)`
  margin-top: 2rem;
  grid-column: span 2;
`

const LogoLink = styled(Link)`
  grid-column: span 2;
  @media (min-width: 768px) {
    grid-column: span 1;
  }
`

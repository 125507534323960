import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Link as GatsbyLink } from 'gatsby'
import { isInternalURL } from '../helpers'

export const Link = ({
  to = '/',
  children,
  color,
  font = 'primary' || 'secondary',
  ...rest
}) => {
  if (isInternalURL(to)) {
    return (
      <IntLink {...rest} to={to} color={color} font={font}>
        {children}
      </IntLink>
    )
  } else {
    return (
      <ExtLink {...rest} href={to} color={color} font={font}>
        {children}
      </ExtLink>
    )
  }
}

const LinkStyles = {
  display: 'inline-flex',
  alignItems: 'center',
  fontFamily: ({ theme, font }) =>
    font === 'primary' ? theme.font.primary : theme.font.secondary,
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '1rem',
  textDecoration: 'none',
  color: ({ theme, color }) => (color ? color : theme.secondary),
  '&:visited': {
    color: ({ theme, color }) => (color ? color : theme.secondary),
  },
  '@media (min-width: 768px)': {
    fontSize: 18,
  },
}

const IntLink = styled(motion(GatsbyLink))({
  ...LinkStyles,
})
const ExtLink = styled(motion.a)({
  ...LinkStyles,
})

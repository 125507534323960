import React from 'react'
import styled, { css } from 'styled-components'

export const Tag = ({ children, isPositive, isRgb, ...rest }) => (
  <Container isPositive={isPositive} isRgb={isRgb} {...rest}>
    {isPositive && '+'}
    {children}
  </Container>
)

const Container = styled.span`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 2px 8px 4px;
  margin: 0 2px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.color.infoBackground};
  color: ${({ theme }) => theme.color.infoText};

  ${({ isPositive, isRgb, theme }) => {
    if (isRgb) {
      return css`
        background-image: ${theme.color.pastelGradient};
        color: white;
        filter: saturate(140%);
      `
    } else if (isPositive) {
      return css`
        background-color: ${theme.color.errorBackground};
        color: ${theme.color.errorText};
      `
    } else {
      return css`
        background-color: ${theme.color.successBackground};
        color: ${theme.color.successText};
      `
    }
  }}
`

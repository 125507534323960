import React from 'react'
import BasePortableText from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import clientConfig from '../../../client-config'
import { Link } from './Link'
import { theme } from './Theme'
import { Figure } from './Figure'
import { BlogBuilderForm } from '../Builder/BlogBuilderForm'

const serializers = {
  marks: {
    link: (props) => (
      <Link to={props.mark.href} style={{ fontFamily: theme.font.secondary }}>
        {props.children}
      </Link>
    ),
  },
  types: {
    authorReference: ({ node }) => <span>{node.author.name}</span>,
    mainImage: Figure,
    youtube: ({ node }) => {
      const { url } = node
      const id = getYouTubeId(url)
      return <YouTube containerClassName="blogpostYoutube" videoId={id} />
    },
    builder: ({ node }) => {
      const { baseBudget } = node

      return (
        <BlogBuilderForm
          baseBudget={{
            label: `${baseBudget}€`,
            value: parseInt(baseBudget),
          }}
        />
      )
    },
  },
}

export const PortableText = ({ blocks, className }) => (
  <BasePortableText
    blocks={blocks}
    serializers={serializers}
    className={className}
    {...clientConfig.sanity}
  />
)

import React, { useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { AlertTriangle, Clipboard } from 'react-feather'
import { theme } from './Theme'
import { Typography } from './Typography'

export function Input({ id, label = '', gridColumn, ...rest }) {
  if (label) {
    return (
      <Container gridColumn={gridColumn}>
        <Typography variant="label" htmlFor={id}>
          {label}
        </Typography>
        <TextInput {...rest} />
      </Container>
    )
  } else {
    return (
      <Container gridColumn={gridColumn}>
        <Typography variant="label" htmlFor={id}>
          <TextInput {...rest} />
        </Typography>
      </Container>
    )
  }
}

const TextInput = ({
  id,
  type = 'input',
  name,
  value,
  placeholder,
  onChange,
  validation = true,
  copyToClipboard = false,
  setCopied,
  ...rest
}) => {
  const [wasFocused, setWasFocused] = useState(false)
  const [isValid, toggleValid] = useState(true)

  function handleChange(e) {
    onChange(e)
    setWasFocused(true)
  }

  function checkValidation() {
    if (!copyToClipboard && wasFocused && validation) {
      toggleValid(true)
    } else {
      toggleValid(false)
    }
  }
  return (
    <>
      <InputContainer
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        isValid={isValid}
        onBlur={checkValidation}
        {...rest}
      />
      <AnimatePresence>
        {!copyToClipboard && !isValid && (
          <IconWrapper
            initial={{ opacity: 0, x: 8 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 8 }}
            transition={{
              duration: 0.5,
              type: 'tween',
              ease: [0, 0, 0.58, 1],
            }}
          >
            <AlertTriangle color={theme.color.errorText} />
          </IconWrapper>
        )}
        {copyToClipboard && (
          <IconWrapper
            onClick={setCopied}
            whileHover={{ stroke: theme.secondary }}
            whileTap={{ scale: 0.975 }}
          >
            <Clipboard color={theme.primary} />
          </IconWrapper>
        )}
      </AnimatePresence>
    </>
  )
}

const Container = styled.div`
  grid-column: ${({ gridColumn }) => gridColumn};
  position: relative;
  margin: auto 0;
`
const InputContainer = styled.input`
  position: relative;
  width: 100%;
  height: 2.5rem;
  padding-left: 8px;
  outline: none;
  border-radius: 6px;
  border: 1px solid;
  background-color: #fff;
  border-color: #fff;
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.primary};
  &:not(:focus) {
    background-color: ${({ isValid, theme }) =>
      isValid ? '#fff' : theme.color.errorBackground};
    border-color: ${({ isValid, theme }) =>
      isValid ? '#fff' : theme.color.errorText};
  }
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`
const IconWrapper = styled(motion.div)`
  position: absolute;
  right: 0.75rem;
  top: 3px;
  padding: 0.25rem;
`

import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Link, LinkButton } from '../utils'

const motionNav = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.15,
    },
  },
  exit: {
    opacity: 0,
  },
}
const motionLinks = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: [0, 0, 0.58, 1],
    },
  },
  exit: {
    opacity: 0,
    y: 10,
  },
}

export const Links = ({ headerHeight }) => (
  <Nav
    headerHeight={headerHeight}
    initial="initial"
    animate="animate"
    exit="exit"
    variants={motionNav}
  >
    <LinkButton
      variant="outlined"
      transparent
      fullWidth
      to="/feedback"
      variants={motionLinks}
    >
      Gib uns Feeback
    </LinkButton>
    <List variants={motionLinks}>
      <li>
        <Link color="white" to="/">
          Startseite
        </Link>
      </li>
      <li>
        <Link color="white" to="/builder/">
          Builder
        </Link>
      </li>
      <li>
        <Link color="white" to="/blog">
          Blog und Anleitungen
        </Link>
      </li>
    </List>
    <List variants={motionLinks}>
      <li>
        <Link color="white" to="/about">
          Über Uns
        </Link>
      </li>
      <li>
        <Link color="white" to="/impressum">
          Impressum
        </Link>
      </li>
      <li>
        <Link color="white" to="/privacy-policy">
          Datenschutzerklärung
        </Link>
      </li>
    </List>
  </Nav>
)
const Nav = styled(motion.nav)`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: ${({ headerHeight }) => headerHeight}px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0.5rem 1.5rem 1rem;

  @media (min-width: 768px) {
    padding: 3rem 2rem 1rem;
  }
`

const List = styled(motion.ul)`
  margin: 1.5rem 0 0;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
  list-style: none;
  li {
    padding: 0 0 0.5rem;
    margin: 0.5rem;
    font-size: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
`

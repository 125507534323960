import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { isInternalURL } from '../helpers'

export const Button = ({
  size = 'medium',
  variant = 'filled',
  scale = 1,
  fullWidth = false,
  disabled = false,
  children = 'Button',
  style,
  ...rest
}) => {
  return (
    <Btn
      {...rest}
      size={size}
      variant={variant}
      scale={scale}
      disabled={disabled}
      style={{
        ...style,
        width: fullWidth ? '100%' : 'fit-content',
      }}
    >
      {children}
    </Btn>
  )
}
export const LinkButton = ({
  size = 'medium',
  variant = 'filled',
  scale = 1,
  fullWidth = false,
  to = '/',
  children = 'Text',
  style,
  ...rest
}) => {
  if (isInternalURL(to)) {
    return (
      <BtnLinkInternal
        {...rest}
        size={size}
        variant={variant}
        style={{
          ...style,
          width: fullWidth ? '100%' : 'fit-content',
        }}
        to={to}
      >
        {children}
      </BtnLinkInternal>
    )
  } else {
    return (
      <BtnLink
        {...rest}
        size={size}
        variant={variant}
        style={{
          width: fullWidth ? '100%' : 'fit-content',
        }}
        href={to}
      >
        {children}
      </BtnLink>
    )
  }
}

export const IconButton = ({
  size = 'medium',
  variant = 'filled',
  scale = 1,
  fullWidth = false,
  children = 'Text',
  style,
  ...rest
}) => {
  return (
    <IconBtn
      {...rest}
      size={size}
      variant={variant}
      scale={scale}
      style={{
        width: fullWidth ? '100%' : 'fit-content',
        ...style,
      }}
    >
      {children}
    </IconBtn>
  )
}

const ButtonProps = {
  padding: ({ size }) =>
    size === 'large'
      ? '1rem 2rem'
      : size === 'medium'
      ? '12px 32px 10px'
      : size === 'small'
      ? '10px 20px 8px'
      : '0.8rem',
  borderRadius: '8px',
  fontFamily: ({ theme }) => theme.font.primary,
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '125%',
  background: '#fff',
  cursor: 'pointer',
  textDecoration: 'none',
  userSelect: 'none',
  transition: 'all 0.2s ease-out',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: ({ theme, variant, transparent }) => {
    if ((variant === 'outlined' && transparent) || variant === 'text') {
      return 'transparent'
    } else if (variant === 'outlined') {
      return theme.color.primary
    } else if (transparent) {
      return 'transparent'
    } else {
      return 'white'
    }
  },

  color: ({ theme, variant }) => {
    if (variant === 'outlined') {
      return 'white'
    } else {
      return theme.color.primary
    }
  },
  border: ({ theme, variant }) => {
    if (variant === 'text') {
      return '1px solid transparent'
    } else {
      return '1px solid white'
    }
  },
  transform: ({ scale }) => (scale ? `scale(${scale})` : 'scale(1)'),
  transfromOrigin: 'center',

  '&:hover': {
    transition: 'all 0.3s ease-out',
    backgroundColor: ({ theme, variant, transparent }) => {
      if (transparent || variant === 'text') {
        return 'rgba(255,255,255,0.15)'
      }
    },
  },

  '&:visited': {
    color: ({ theme, variant }) => {
      if (variant === 'outlined') {
        return 'white'
      } else {
        return theme.color.primary
      }
    },
  },
  '&:disabled': {
    cursor: 'no-drop',
    borderColor: '#ccc',
    background: '#ccc',
    textDecoration: 'line-through',
  },
  '@media (min-width: 768px)': {
    padding: ({ size }) =>
      size === 'large'
        ? '1rem 2rem 1.1rem'
        : size === 'medium'
        ? '0.8rem 2rem 0.8rem'
        : '10px',
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '125%',
  },
}

const Btn = styled(motion.button)({
  ...ButtonProps,
})

const BtnLinkInternal = styled(motion(Link))({
  ...ButtonProps,
  textAlign: 'center',
})
const BtnLink = styled(motion.a)({
  ...ButtonProps,
  textAlign: 'center',
})

const IconBtn = styled(Btn)`
  border-radius: 12px;
  display: inline-flex;
  outline: none;
  padding: ${({ size }) =>
    size === 'large'
      ? '0.8rem'
      : size === 'medium'
      ? '0.5rem'
      : size === 'small'
      ? '0.3rem'
      : '0.5rem'};
`

import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

export const Dots = ({ color = '#fff', size = 4, gap = 4 }) => {
  return (
    <Container
      style={{
        '--dot-color': color,
        '--dot-size': `${size}px`,
        '--dot-gap': `${gap}px`,
      }}
    >
      <Dot
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1.25,
          delay: 0,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
      <Dot
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1.25,
          delay: 0.25,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
      <Dot
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1.25,
          delay: 0.5,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
    </Container>
  )
}

const Container = styled(motion.span)`
  display: inline-flex;
  align-items: baseline;
  span {
    width: var(--dot-size, 4px);
    height: var(--dot-size, 4px);
    background-color: var(--dot-color, #fff);
    margin: 0 var(--dot-gap, 4px);
  }
`

const Dot = styled(motion.span)`
  border-radius: 50%;
  animation: bounceAnimation 1.25s ease-in-out infinite;
`

import { useEffect, useState } from 'react'
import { fetchGetOptions } from './fetchHelpers'

export async function fetchBudget() {
  try {
    const response = await fetch(
      process.env.GATSBY_GHOSTBUILDER_BUDGET_API,
      fetchGetOptions
    )
    const body = await response.json()
    return body
  } catch (err) {
    console.log(err.message)
  }
}

export function useBudget() {
  const [budget, setBudget] = useState([{ label: '800€', value: 800 }])

  useEffect(() => {
    async function fetchBudget() {
      try {
        const response = await fetch(
          process.env.GATSBY_GHOSTBUILDER_BUDGET_API,
          fetchGetOptions
        )
        const body = await response.json()
        setBudget(body)
      } catch (err) {
        console.log(err.message)
      }
    }
    if (budget.length < 2) {
      fetchBudget()
    }
  }, [budget.length])

  return [budget, setBudget]
}

import Cookies from 'universal-cookie'

const cookie = new Cookies()

export const cookieAge = (days = 30) => days * 24 * 60 * 60

export const setBuilderCookie = (data, days = 30) => {
  if (cookie.get('isCookieAccepted')) {
    cookie.set('quickbuilderData', data, {
      path: '/',
      maxAge: cookieAge(days),
    })
  }
}

import React, { useEffect, useState } from 'react'
import { AnimateSharedLayout, motion } from 'framer-motion'
import styled from 'styled-components'

export function ButtonGroup(props) {
  const { options, onChange, name } = props
  // const [isSelected, setSelected] = useState(
  //   options.find((o) => o.default).value
  // )
  const [isSelected, setSelected] = useState(props.defaultOption)

  useEffect(() => {
    setSelected(props.defaultOption)
  }, [props.defaultOption])

  function onChangeRadio(e) {
    setSelected(e.target.value)
    onChange(e)
  }

  return (
    <Container
      id={props.labelId}
      onChange={(e) => onChangeRadio(e)}
      gridColumn={props.gridColumn}
    >
      <AnimateSharedLayout>
        {options.map((option) => (
          <React.Fragment key={option.value}>
            <Input
              type="radio"
              id={`${name}-${option.value}`}
              value={option.value}
              name={name}
              // defaultChecked={option.default}
              // defaultChecked={option.value === props.defaultOption}
              checked={option.value === props.defaultOption}
              readOnly
            />
            <InputLabel
              htmlFor={`${name}-${option.value}`}
              key={`${option.value}-label`}
              initial={false}
              animate={{
                visibility: 'visible',
              }}
            >
              {isSelected === option.value && (
                <InputBackground
                  layoutId="radioBackground"
                  animate={{
                    backgroundColor: '#fff',
                  }}
                  transition={{
                    type: 'spring',
                    stiffness: 300,
                    damping: 30,
                  }}
                />
              )}
              {option.label}
            </InputLabel>
          </React.Fragment>
        ))}
      </AnimateSharedLayout>
    </Container>
  )
}

const Container = styled(motion.div)`
  position: relative;
  grid-column: ${({ gridColumn }) => gridColumn && gridColumn};
  display: flex;
  width: 100%;
  height: 3rem;
  border-radius: 8px;
  background-color: rgba(226, 255, 255, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  justify-content: space-around;
  align-items: center;
  input:checked + label {
    color: white;
    color: ${({ theme }) => theme.color.primary};
  }
  input:disabled + label {
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    transition: all 0.6s ease-in-out;
    color: white;
    border: 2px 1px solid white;
    cursor: not-allowed;
  }
  @media (min-width: 768px) {
    height: 3.5rem;
  }
`
const Input = styled(motion.input)`
  position: absolute;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
`
const InputLabel = styled(motion.label)`
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  text-align: center;
  color: white;
  font-family: ${({ theme }) => theme.font.proxima};
  font-weight: 600;
  border-radius: 6px;
  z-index: 2;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  user-select: none;
  position: relative;
  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`
const InputBackground = styled(motion.div)`
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 3px;
  left: 1px;
  right: 1px;
  bottom: 3px;
  border-radius: 6px;
  z-index: -1;
`

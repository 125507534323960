export const toBlogDate = (d) => {
  let monthNames = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
  const date = new Date(d)

  let day = date.getDate()
  let monthIndex = date.getMonth()
  let monthName = monthNames[monthIndex]

  let year = date.getFullYear()

  return `${day} ${monthName} ${year}`
}

import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

export function Seo({ description, title, titleTemplate, image, keywords }) {
  const { origin } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultDescription,
    defaultImage,
    twitterUsername,
    keywords: defaultKeywords,
    siteUrl,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    titleTemplate: titleTemplate || `| ${defaultTitle}`,
    description: description || defaultDescription,
    image: `${siteUrl}/${image || defaultImage}`,
    url: origin,
    keywords: keywords || defaultKeywords,
  }

  return (
    <Helmet title={seo.title} titleTemplate={`%s ${seo.titleTemplate}`}>
      <html lang="de" />
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && (
        <meta property="og:image" loading="lazy" content={seo.image} />
      )}
      {seo.image && <meta property="og:image:width" content="1200" />}
      {seo.image && <meta property="og:image:height" content="630" />}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.keywords > 1 && <meta name="keywords" content={seo.keywords} />}
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
      <link
        rel="preload"
        href="https://use.typekit.net/jor1wbh.css"
        as="style"
        onLoad="this.onload=null;this.rel='stylesheet'"
      />
      {/* <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA4_GHOSTBUILDER_KEY}`}
      ></script> */}
    </Helmet>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        defaultImage: metaImage
        author
        twitterUsername
        keywords
        siteUrl
      }
    }
  }
`

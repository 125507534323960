import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const LogoCircle = ({ className, alt = 'Ghostbuilder Logo' }) => {
  return (
    <StaticImage
      src="../../images/round-ghostbuilder-Logo.png"
      alt={alt}
      className={className}
      placeholder="tracedSVG"
    />
  )
}

// /**
//  * Implement Gatsby's Browser APIs in this file.
//  *
//  * See: https://www.gatsbyjs.org/docs/browser-apis/
//  */

// // You can delete this file if you're not using it
const React = require('react')
const ReactDOM = require('react-dom')
const {
  default: GlobalWrapper,
} = require('./src/components/layout/globalWrapper')

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <GlobalWrapper {...props}>{element}</GlobalWrapper>
}

import React, { useEffect, useState } from 'react'
import { Menu, X } from 'react-feather'
import useMedia from 'use-media'
import styled, { css } from 'styled-components'
import { useLocation } from '@reach/router'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { IconButton, Link, LogoText, rgba } from '../utils'
import { Links } from './Navigation'

export function Header() {
  const [isOpen, toggleIsOpen] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const isLargeScreen = useMedia({ minWidth: 768 })

  const { pathname } = useLocation()
  const linkColor = pathname.includes('/blog/') ? '#2a1e4d' : '#fff'

  const linkHoverMotion = {
    backgroundImage: `linear-gradient(#fff, #fff)`,
    backgroundSize: `100% 2px`,
    transition: { duration: 0.3, ease: 'easeIn' },
  }

  useEffect(() => {
    toggleIsOpen(false)
  }, [pathname])

  const headerControls = useAnimation()
  useEffect(() => {
    if (isOpen) {
      headerControls.start('animate')
      document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
      document.getElementsByTagName('html')[0].style.position = 'fixed'
    } else {
      headerControls.start('exit')
      document.getElementsByTagName('html')[0].style.overflowY = 'initial'
      document.getElementsByTagName('html')[0].style.position = 'initial'
    }
  }, [isOpen, headerControls])

  useEffect(() => {
    var headerWrapperHeight =
      document.getElementById('headerWrapper').clientHeight
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    )
    setHeaderHeight(vh - headerWrapperHeight)
  }, [])

  return (
    <AnimatePresence>
      <Container
        pathname={pathname}
        layout
        initial="initial"
        animate={headerControls}
        exit="exit"
      >
        <Wrapper id="headerWrapper" layout>
          <Link to="/" aria-label="Ghostbuilder Logo">
            <LogoText
              style={{
                height: 48,
                width: 'auto',
              }}
              color={linkColor}
            />
          </Link>
          {isLargeScreen && !isOpen && (
            <LinkContainer pathname={pathname}>
              <Link
                color={linkColor}
                to="/builder/"
                whileHover={linkHoverMotion}
              >
                Builder
              </Link>
              <Link color={linkColor} to="/blog/" whileHover={linkHoverMotion}>
                Blog
              </Link>
              <Link
                color={linkColor}
                to="/feedback"
                whileHover={linkHoverMotion}
              >
                Feedback
              </Link>
              <Link color={linkColor} to="/about" whileHover={linkHoverMotion}>
                Über Uns
              </Link>
            </LinkContainer>
          )}
          {!isLargeScreen && (
            <HeaderButton
              transparent
              onClick={() => toggleIsOpen(!isOpen)}
              aria-label="toggle Navigation"
            >
              {!isOpen ? <Menu color="white" /> : <X color="white" />}
            </HeaderButton>
          )}
        </Wrapper>
        {isOpen && <Links headerHeight={headerHeight} />}
      </Container>
    </AnimatePresence>
  )
}

const Container = styled(motion.header)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  background-color: ${({ theme }) => rgba(theme.primary, 0.7)};
  @supports (backdrop-filter: blur(30px)) {
    background-color: ${({ theme, pathname }) =>
      pathname.includes('/blog/')
        ? rgba(theme.color.white, 1.0)
        : rgba(theme.primary, 0.0)};
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
  }

  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 10;
  overflow-y: auto;
`

const Wrapper = styled(motion.div)`
  max-width: 1072px;
  padding: 1rem 1.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
  }
`

const HeaderButton = styled(IconButton)`
  justify-self: end;
  @media (min-width: 768px) {
    grid-column: 3;
    display: none;
  }
`

const LinkContainer = styled(motion.nav)`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > * {
    margin: 0 1rem;
    padding-block: 0.5rem;
  }

  a {
    background-image: linear-gradient(transparent, transparent);
    background-position: 0% 90%;
    background-repeat: no-repeat;
    background-size: 100% 2px;
  }

  ${({ pathname }) => {
    if (!pathname.includes('/blog/')) {
      return css`
        a {
          text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
        }
      `
    }
  }}
`

export function loadBuilderCookies(cookieState, dispatch) {
  const {
    budget = {
      value: 800,
      label: '800€',
    },
    cpu = 'auto',
    gpu = 'auto',
    provider = {
      value: 'cheapest',
      label: 'Günstigster',
    },
    isCase = true,
    isPlaceholder = false,
  } = cookieState
  dispatch({ type: 'budget', value: budget })
  dispatch({ type: 'cpu', value: cpu })
  dispatch({ type: 'gpu', value: gpu })
  dispatch({ type: 'case', value: isCase })
  dispatch({ type: 'placeholder', value: isPlaceholder })
  dispatch({ type: 'provider', value: provider })
}

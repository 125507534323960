export const white = '#fff'
export const black = '#222'
export const primary = '#2a1e4d'
export const secondary = '#e23580'
export const purple800 = '#110c1f'
export const purple700 = '#19122e'
export const purple600 = '#22183e'
export const purple500 = primary
export const purple400 = '#554b71'
export const purple300 = '#7f7894'
export const purple200 = '#aaa5b8'
export const pink800 = '#5a1533'
export const pink700 = '#88204d'
export const pink600 = '#b52a66'
export const pink500 = secondary
export const pink400 = '#e85d99'
export const pink300 = '#ee86b3'
export const pink200 = '#f3aecc'

export const green200 = '#95ffb8'
export const green700 = '#012407'
export const yellow200 = '#f2ca01'
export const yellow700 = '#635200'
export const blue200 = '#87cefa'
export const blue700 = '#0000cd'

export const infoBackground = blue200
export const infoText = blue700
export const successBackground = green200
export const successText = green700
export const warningBackground = yellow200
export const warningText = yellow700
export const errorBackground = pink200
export const errorText = pink600

export const grey = '#ccc'

export const labelText = 'rgba(255, 255, 255, 0.7)'

export const pastel = {
  orange: '#feac5e',
  pink: '#c779d0',
  blue: '#4bc0c8',
}
export const pastelGradient = `linear-gradient(to right, ${pastel.orange}, ${pastel.pink}, ${pastel.blue})`

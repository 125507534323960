import { useReducer } from 'react'

const initialState = {
  budget: {
    value: 800,
    label: '800€',
  },
  cpu: 'auto',
  gpu: 'auto',
  provider: {
    value: 'cheapest',
    label: 'Günstigster',
  },
  isCase: true,
  isPlaceholder: false,
}

function reducer(state, action) {
  switch (action.type) {
    case 'usecase':
      return { ...state, usecase: action.value }
    case 'cpu':
      return { ...state, cpu: action.value }
    case 'gpu':
      return { ...state, gpu: action.value }
    case 'provider':
      return { ...state, provider: action.value }
    case 'budget':
      return { ...state, budget: action.value }
    case 'case':
      return { ...state, isCase: action.value }
    case 'placeholder':
      return { ...state, isPlaceholder: action.value }
    default:
      break
  }
}

export function useQuickBuilderState() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return [state, dispatch]
}

import * as colors from './Colors'

export const theme = {
  primary: colors.primary,
  secondary: colors.secondary,
  color: {
    ...colors,
  },
  font: {
    sofia: 'sofia-pro, sans-serif',
    proxima: 'proxima-nova, sans-serif',
    primary: 'sofia-pro, sans-serif',
    secondary: 'proxima-nova, sans-serif',
  },
}

import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ToggleButton = ({
  defaultChecked,
  onChange,
  disabled,
  dispatch,
  dispatchType,
  style,
}) => {
  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    setToggle(defaultChecked)
  }, [defaultChecked])

  const triggerToggle = () => {
    if (disabled) {
      return
    }
    setToggle(!toggle)

    if (typeof onChange === 'function') {
      onChange(!toggle)
    }
    if (typeof dispatch === 'function') {
      dispatch({ type: dispatchType, value: !toggle })
    }
  }

  return (
    <Toggle
      style={style}
      onClick={triggerToggle}
      className={`wrg-toggle ${toggle ? 'wrg-toggle--checked' : ''}`}
    >
      <motion.div
        animate={{
          background: toggle
            ? 'rgba(226, 53, 128, 1)'
            : 'rgba(120, 120, 128, 0.16)',
        }}
        transition={{ duration: 0.125 }}
        className="wrg-toggle-container"
      >
        <div className="wrg-toggle-check">
          <span></span>
        </div>
        <div className="wrg-toggle-uncheck">
          <span></span>
        </div>
      </motion.div>
      <motion.div
        animate={{ x: toggle ? 20 : 0 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        className="wrg-toggle-circle"
      ></motion.div>
      <input
        type="checkbox"
        aria-label="Toggle Button"
        className="wrg-toggle-input"
      />
    </Toggle>
  )
}

const Toggle = styled.div`
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  .wrg-toggle-input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .wrg-toggle-check,
  .wrg-toggle-uncheck {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    opacity: 0;
    transition: opacity 0.25s ease;
  }
  .wrg-toggle-check {
    left: 8px;
  }
  .wrg-toggle-uncheck {
    opacity: 1;
    right: 10px;
  }

  .wrg-toggle-uncheck span,
  .wrg-toggle-check span {
    align-items: center;
    display: flex;
    height: 10px;
    justify-content: center;
    position: relative;
    width: 10px;
  }

  .wrg-toggle-container {
    width: 50px;
    height: 30px;
    padding: 0;
    border-radius: 30px;
    background-color: rgba(120, 120, 128, 0.16);
    border: 2px solid ${({ theme }) => theme.secondary};
    transition: all 0.2s ease;
  }

  .wrg-toggle-circle {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 24px;
    height: 24px;
    /* border: 1px solid #4d4d4d; */
    border-radius: 50%;
    background-color: #fafafa;
    box-sizing: border-box;
  }

  .wrg-toggle--checked .wrg-toggle-check {
    opacity: 1;
  }
  .wrg-toggle--checked .wrg-toggle-uncheck {
    opacity: 0;
  }
  .wrg-toggle--checked .wrg-toggle-circle {
    transform: translateX(27px);
  }

  .wrg-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
`

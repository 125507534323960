import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Typography, TypographyComponent } from '../utils/Typography'

export function BlogSlider({
  value,
  defaultValue = 8,
  min = 0,
  max,
  name,
  datalist,
  dispatch,
  dispatchType,
  style,
  color,
}) {
  const [defaultValueToRerender, setDefaultValueToRerender] = useState()

  useEffect(() => {
    if (defaultValue) setDefaultValueToRerender(defaultValue)
  }, [])

  function handleInputChange({ target }) {
    dispatch({ type: dispatchType, value: datalist[target.value] })
  }

  return (
    <Container style={style}>
      <Label variant="label" htmlFor={name} color={color}>
        <TopLabel>Budget</TopLabel>
        <BottomLabel>Wie viel soll investiert werden?</BottomLabel>
      </Label>
      <SliderValue>{value?.label}</SliderValue>
      <Slider
        color={color}
        key={defaultValueToRerender}
        id={name}
        type="range"
        name={name}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={defaultValue}
        list="tickmarks"
        min={min}
        max={max}
        step={1}
        defaultValue={defaultValue}
        onChange={handleInputChange}
      />
    </Container>
  )
}

const Container = styled.div`
  grid-column: 1 / 5;
  display: grid;
  align-items: flex-start;
  justify-content: center;
  grid-template-rows: 1fr;
  width: 100%;
  grid-template-columns: repeat(3, minmax(3rem, 1fr));
  gap: 1px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
`

const Label = styled(Typography)`
  grid-column: span 2;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  max-width: 100%;
  color: ${({ color, theme }) => {
    if (color) return color
    else return theme?.color?.labelText
  }};
`

const TopLabel = styled(Typography)`
  color: #382867;
  font-size: 20px;
  line-height: 100%;
`
const BottomLabel = styled(Typography)`
  font-size: 14px;
  line-height: 100%;
  color: #9b89d1;
  word-break: break-word;
`

const SliderValue = styled(Typography)`
  grid-column: 3;
  justify-self: end;
  font-size: 30px;
  line-height: 100%;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;

  @media (min-width: 768px) {
    font-size: 40px;
  }
`

const Slider = styled.input`
  grid-column: 1 / 4;
  outline: none;
  -webkit-appearance: none;
  background: ${({ color }) => {
    if (color) return color
    else return `rgba(255, 255, 255, 0.7)`
  }};
  border-radius: 4px;
  width: 100%;
  margin: 1rem 0 0;
  --trackHeight: 5px;
  --trackRadius: var(--trackHeight);
  --thumbHeight: 1.6rem;
  --thumbWidth: 2.8rem;
  --thumbRadius: calc(var(--thumbHeight) / 2);
  --thumbBorderWidth: 2.5px;
  --borderColor: ${({ theme }) => theme.color.pink500};

  @media (min-width: 768px) {
    --trackHeight: 6px;
    --trackRadius: var(--trackHeight);
    --thumbHeight: 1.5rem;
    --thumbWidth: 2.5rem;
    --thumbRadius: calc(var(--thumbHeight) / 2);
    --thumbBorderWidth: 2px;
    margin: 0.5rem 0 0;
  }

  // safari 10+ only
  @media not all and (min-resolution: 0.001dpcm) {
    margin: 1rem 0 0;
  }
  // Chrome, Opera, Safari, Edge
  &::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};

    height: var(--trackHeight);
    border-radius: var(--trackRadius);
  }
  &::-webkit-slider-thumb {
    box-sizing: border-box;
    height: var(---thumbHeight);
    width: var(-----thumbWidth);
    border-color: var(--borderColor);
    border-style: solid;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;

    border-width: var(--thumbBorderWidth);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
    border-radius: var(--thumbRadius);
    margin-top: -0.55rem;

    @media (min-width: 768px) {
      margin-top: -9px;
    }
  }

  //Firefox
  &::-moz-range-track {
    width: 100%;
    cursor: pointer;
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};

    height: var(--trackHeight);
    border-radius: 4px;
  }
  &::-moz-range-thumb {
    box-sizing: border-box;
    border-color: var(--borderColor);
    border-style: solid;
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};
    cursor: pointer;

    border-width: var(--thumbBorderWidth);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
    border-radius: 20px;
  }
  &::-moz-focus-outer {
    border: none;
  }

  // edge
  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;

    height: var(--trackHeight);
    padding: 4px;
  }
  &::-ms-fill-lower {
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};
    border-radius: 10px;
  }
  &::-ms-fill-upper {
    background: ${({ color, theme }) => {
      if (color) return color
      else return `rgba(255, 255, 255, 0.7)`
    }};
    border-radius: 10px;
  }
  &::-ms-thumb {
    box-sizing: border-box;
    border-color: var(--borderColor);
    border-style: solid;
    background: white;
    cursor: pointer;

    border-width: var(--thumbBorderWidth);
    height: var(--thumbHeight);
    width: var(--thumbWidth);
    border-radius: var(--thumbRadius);
    transform: translateY(4px);
  }
  &:focus::-ms-fill-lower {
    background: white;
  }
  &:focus::-ms-fill-upper {
    background: white;
  }
`

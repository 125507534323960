import React, { useState } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Cookie from 'universal-cookie'
import { Info } from 'react-feather'
import { Button, Link, rgba, Typography } from '../utils'
import { cookieAge } from '../helpers'

const cookie = new Cookie()

export function CookieToast() {
  const initalAccept = () => {
    if (
      typeof window !== `undefined` &&
      cookie.get('isCookieAccepted') !== null &&
      cookie.get('isCookieAccepted')
    ) {
      return true
    } else {
      return false
    }
  }
  const [isAccepted, setIsAccepted] = useState(initalAccept)

  const handleAccept = () => {
    setIsAccepted(true)
    if (typeof window !== `undefined`) {
      cookie.set('isCookieAccepted', true, { maxAge: cookieAge() })
    }
  }
  const handleDecline = () => {
    setIsAccepted(true)
    if (typeof window !== `undefined`) {
      cookie.set('isCookieAccepted', false, { maxAge: cookieAge() })
    }
  }

  return (
    <AnimatePresence>
      {!isAccepted && (
        <ContainerCookie
          initial={{ y: -20, left: '50%', x: '-50%' }}
          exit={{ y: 300, transition: { duration: 1, ease: 'easeIn' } }}
          role="dialog"
        >
          <Typography variant="h4">
            <Info size={24} style={{ marginRight: 8 }} />
            Cookie Einstellungen
          </Typography>
          <Typography style={{ display: 'block' }}>
            Diese Seite verwendet Cookies. Wenn du sie weiter verwendest, gehen
            wir von deinem Einverständnis aus.{' '}
            <Link to="/privacy-policy/">Erfahre mehr</Link>.
          </Typography>
          <ButtonWrapper>
            <Button size="small" onClick={handleAccept}>
              Annehmen
            </Button>
            <Button variant="text" size="small" onClick={() => handleDecline()}>
              Ablehnen
            </Button>
          </ButtonWrapper>
        </ContainerCookie>
      )}
    </AnimatePresence>
  )
}

const ContainerCookie = styled(motion.div)`
  z-index: 100;
  font-weight: 500;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 5px 18px -5px rgba(0, 0, 0, 0.2);
  background-color: ${rgba('#fff', 0.95)};
  @supports (backdrop-filter: blur(30px)) {
    background-color: ${rgba('#fff', 0.6)};
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border: 2px solid ${rgba('#fff', 0.15)};
  }
  color: ${({ theme }) => theme.color.purple600};
  padding: 1rem;
  width: 95%;
  left: 0;
  height: auto;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    margin-bottom: 0.5rem;
  }
  a,
  a:visited {
    color: ${({ theme }) => theme.color.secondary};
  }
  button {
    padding-block: 0.5rem;
  }

  @media (min-width: 768px) {
    max-width: 528px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`

import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import clientConfig from '../../../client-config'

export function Figure({ node }) {
  const fluidProps = getGatsbyImageData(
    node.asset._id,
    { maxWidth: 675 },
    clientConfig.sanity
  )

  return (
    <figure>
      <GatsbyImage
        image={fluidProps}
        alt={node.alt}
        layout="constrained"
        placeholder="blurred"
      />
      <figcaption>{node.caption}</figcaption>
    </figure>
  )
}

import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import useMedia from 'use-media'

export function ImageFrame({ style, className, imageData }) {
  const imageDesktop = getImage(imageData.desktop.asset)
  const imageMobile = getImage(imageData.mobile.asset)

  const isTablet = useMedia({ minWidth: 768 })
  return (
    <Container style={style} className={className}>
      <a href={imageData.link} target="blank" rel="noopener">
        {isTablet ? (
          <GatsbyImage image={imageDesktop} alt={imageData.desktop.alt} />
        ) : (
          <GatsbyImage image={imageMobile} alt={imageData.mobile.alt} />
        )}
      </a>
    </Container>
  )
}

const Container = styled.div`
  margin: 0 auto 1.5rem;
  max-width: 1024px;
  height: auto;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }
  @media (min-width: 1024px) {
    /* margin-bottom: 3rem; */
  }
`

import React from 'react'
import styled from 'styled-components'
import { Typography } from './Typography'

export function Textarea({ id, label = '', gridColumn, ...rest }) {
  if (label) {
    return (
      <Container gridColumn={gridColumn}>
        <Typography variant="label" htmlFor={id}>
          {label}
        </Typography>
        <Area {...rest} />
      </Container>
    )
  } else {
    return (
      <Container gridColumn={gridColumn}>
        <Typography variant="label" htmlFor={id}>
          <Area {...rest} />
        </Typography>
      </Container>
    )
  }
}

const Area = ({ id, name, value, placeholder, onChange, ...rest }) => (
  <AreaContainer
    name={name}
    id={id}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    {...rest}
  />
)

const Container = styled.div`
  grid-column: ${({ gridColumn }) => gridColumn};
  position: relative;
  margin: auto 0;
`
const AreaContainer = styled.textarea`
  position: relative;
  width: 100%;
  min-height: 10rem;
  padding-left: 8px;
  padding-top: 5px;
  outline: none;
  border-radius: 6px;
  border: 1px solid;
  font-size: 0.8rem;
  font-family: ${({ theme }) => theme.font.secondary};
  font-weight: 500;
  color: ${({ theme }) => theme.primary};
  background-color: #fff;
  border-color: #fff;
  @media (min-width: 768px) {
    font-size: 1rem;
  }
`

import React from 'react'
import AsyncSelect from 'react-select'
import { isMobileDevice } from '../helpers'
import { rgba, primary, purple500, purple600 } from '.'

export function Dropdown({
  id,
  value,
  options,
  onChange,
  placeholder,
  isLoading,
  ...rest
}) {
  return (
    <AsyncSelect
      id={id}
      name={id}
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      styles={dropdownStyles()}
      isSearchable={false}
      // closeMenuOnSelect
      // isMulti
      // menuIsOpen
      {...rest}
    />
  )
}

const dropdownStyles = () => ({
  container: (styles) => ({
    ...styles,
    margin: '4px 0',
    cursor: 'pointer',
    fontSize: isMobileDevice() ? '0.8rem' : 'initial',
  }),
  menu: (styles) => ({
    ...styles,
    top: '42px',
    position: 'absolute',
    borderRadius: '10px',
    fontSize: isMobileDevice() ? '1rem' : 'initial',
    border: '2px solid rgba(255,255,255,0.3)',
    backgroundColor: rgba(primary, 0.95),
    '@supports (backdrop-filter: blur(30px))': {
      backgroundColor: 'rgba(255,255,255,0.3)',
      backdropFilter: 'blur(30px)',
      webkitBackdropFilter: 'blur(30px)',
    },
  }),
  control: (styles) => ({
    ...styles,
    height: '3rem',
    backgroundColor: 'white',
    borderRadius: '6px',
    borderColor: 'white',
    boxShadow: '0 0 1px #e23580',
    cursor: 'pointer',
    ':hover': {
      borderColor: '#e23580',
      boxShadow: '0 0 1px #e23580',
    },
    ':active': {
      borderColor: '#e23580',
      boxShadow: '0 0 1px #e23580',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: '1rem',
    fontWeight: 550,
  }),
  singleValue: (styles) => ({
    ...styles,
    // fontSize: '1rem',
    color: purple500,
    fontWeight: 550,
  }),
  indicatorSeparator: (styles) => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: purple500,
  }),
  option: (styles, { data, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: 550,
      fontFamily: 'sofia-pro, sans-serif',
      alignItems: 'center',
      cursor: 'pointer',
      width: 'auto',
      margin: '4px 4px',
      borderRadius: '8px',
      backgroundColor: isSelected
        ? 'rgba(255,255,255, 1)'
        : isFocused
        ? 'rgba(255,255,255,1)'
        : null,
      color: isSelected ? purple500 : isFocused ? purple600 : null,
      textShadow:
        isSelected || isFocused ? null : '0px 0px 10px rgba(0, 0, 0, 0.5)',
      animation: 'all 0.3s ease-out',
    }
  },
})

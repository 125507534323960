export function infoParser(data) {
  const addSupportObject = (
    objectLabel,
    keywords,
    replaceString = '',
    unit = ''
  ) => {
    let obj = {
      label: objectLabel,
      value: [],
    }
    let compressRemoveObject = []
    const isWordInString = (str) =>
      str.split(/\b/).some(Array.prototype.includes.bind(keywords))

    data.forEach((element) => {
      if (isWordInString(element.label)) {
        if (element.value === '1') {
          obj.value.push(` ${element.label.replace(replaceString, '')}${unit}`)
        }
        compressRemoveObject.push(element)
      }
    })

    data = data.filter(function (item) {
      return !this.has(item.label)
    }, new Set(compressRemoveObject.map((item) => item.label)))

    if (obj.value.length > 0) {
      data.push(obj)
    }
    // return data
  }

  addSupportObject(
    'supportedSockets',
    ['socket1151v2', 'socket2066', 'socketAM4', 'socketTR4'],
    'socket'
  )
  addSupportObject(
    'supportedRadiatorFront',
    [
      'radiatorFront120',
      'radiatorFront140',
      'radiatorFront240',
      'radiatorFront280',
      'radiatorFront360',
    ],
    'radiatorFront',
    'mm'
  )
  addSupportObject(
    'supportedRadiatorUp',
    [
      'radiatorUp120',
      'radiatorUp140',
      'radiatorUp240',
      'radiatorUp280',
      'radiatorUp360',
    ],
    'radiatorUp',
    'mm'
  )
  addSupportObject(
    'supportedRadiatorBack',
    ['radiatorBack120', 'radiatorBack140'],
    'radiatorBack',
    'mm'
  )

  return data.map((item, idx) => {
    item.value = item.value.toString()
    item.index = idx

    const parseItem = parseValue(item)
    return translateItem(parseItem)
  })
}

function parseValue(data) {
  let parsedItem = data
  const checkUnit =
    !parsedItem.value.includes('GHz') &&
    !parsedItem.value.includes('MHz') &&
    !parsedItem.value.includes('MB/s') &&
    !parsedItem.value.includes('mm') &&
    !parsedItem.value.includes('Zoll') &&
    !parsedItem.value.includes('M.2') &&
    !parsedItem.value.includes('TB') &&
    !parsedItem.value.includes('GB') &&
    !parsedItem.value.includes('Mini-ITX') &&
    !parsedItem.value.includes('mATX') &&
    !parsedItem.value.includes('ATX') &&
    !parsedItem.value.includes('EATX')
  const parseValue = (label, trueVal = 'Ja', falseVal = 'Nein') => {
    if (parsedItem.label === label && parsedItem.value === '1') {
      return (parsedItem.value = trueVal)
    } else if (parsedItem.label === label && parsedItem.value === '0') {
      return (parsedItem.value = falseVal)
    }
  }
  const setDot = (x) => x.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  const setUnit = (label, unit) => {
    if (parsedItem.label === label)
      return (parsedItem.value = `${setDot(parsedItem.value)} ${
        checkUnit ? unit : ''
      }`.trim())
  }

  parseValue('needsGPU')
  parseValue('overclocking')
  parseValue('wifi')
  parseValue('rgb')
  parseValue('nvme')
  parseValue('socket1151v2')
  parseValue('socket2066')
  parseValue('socketAM4')
  parseValue('socketTR4')

  setUnit('boostclock', 'MHz')
  setUnit('baseclock', 'MHz')
  setUnit('length', 'mm')
  setUnit('clock', 'MHz')
  setUnit('read', 'MB/s')
  setUnit('write', 'MB/s')
  setUnit('tbw', 'TB')
  setUnit('radiatorSize', 'mm')
  setUnit('coolerHeight', 'mm')
  setUnit('gpuLengthMax', 'mm')
  setUnit('coolerHeightMax', 'mm')
  setUnit('capacitySsd', 'GB')
  setUnit('capacity', 'GB')
  setUnit('capacityHdd', 'TB')
  setUnit('power', 'Watt')
  setUnit('format', 'Zoll')

  return parsedItem
}

function translateItem(data) {
  let parsedItem = data
  const repLab = (initial, newVal) =>
    (parsedItem.label = parsedItem.label.replace(initial, newVal))

  repLab('manufacturer', 'Hersteller')
  repLab('cores', 'Kerne')
  repLab('threads', 'Threads')
  repLab('baseclock', 'Basistakt')
  repLab('boostclock', 'Boosttakt')
  repLab(/\b(socket)\b/g, 'Sockel')
  repLab('needsGPU', 'Benötigt Grafikkarte')
  repLab('chipset', 'Chipsatz')
  repLab('overclocking', 'Übertaktbar')
  repLab('m2slots', 'M.2-Slots')
  repLab('pcie40slots', 'PCIe 4.0 Slots')
  repLab('pcie30slots', 'PCIe 3.0 Slots')
  repLab('usb2.0', 'USB 2.0')
  repLab('usb3.2Gen1', 'USB 3.2 Gen 1')
  repLab('usb3.2Gen2', 'USB 3.2 Gen 2')
  repLab('sata3', 'Sata III')
  repLab(/\b(wifi)\b/g, 'WLAN')
  repLab(/\b(wifiStandard)\b/g, 'Wifi-Standard')
  repLab(/\b(wlanStandard)\b/g, 'WLAN-Standard')
  repLab('lan1', 'LAN-Port 1')
  repLab('lan2', 'LAN-Port 2')
  repLab('rgb', 'RGB')
  repLab('gpuManufacturer', 'GPU-Hersteller')
  repLab('length', 'Länge')
  repLab(/\b(pins6)\b/g, '6Pin-Ports')
  repLab(/\b(pins8)\b/g, '8Pin-Ports')
  repLab(/\b(capacity)\b/g, 'Kapazität')
  repLab('capacitySsd', 'Kapazität')
  repLab('capacityHdd', 'Kapazität')
  repLab('modules', 'Anzahl Module')
  repLab(/\b(clock)\b/g, 'Speichertakt')
  repLab(/\b(read)\b/g, 'Lesen')
  repLab(/\b(write)\b/g, 'Schreiben')
  repLab('nvme', 'Nvme')
  repLab('connection', 'Anbindung')
  repLab('tbw', 'TBW')
  repLab('radiatorSize', 'Radiatorgröße')
  repLab(/\b(coolerHeight)\b/g, 'Kühlergröße')
  repLab('supportedSockets', 'Unterstützte Sockel')
  repLab('power', 'Leistung')
  repLab(/\b(pins6plus2)\b/g, '6+2Pin-Ports')
  repLab(/\b(pins8plus4)\b/g, '8+4Pin-Ports')
  repLab(/\b(pins4plus4)\b/g, '4+4Pin-Ports')
  repLab('80plus', '80Plus')
  repLab('color', 'Farbe')
  repLab('sidePanel', 'Seitenteil')
  repLab('gpuLengthMax', 'GPU-Länge max.')
  repLab(/\b(coolerHeightMax)\b/g, 'Kühlergröße max.')
  repLab('drives2,5', '2,5 Zoll-Plätze')
  repLab('drives3,5', '3,5 Zoll-Plätze')
  repLab('supportedRadiatorFront', 'Radiator Front')
  repLab('supportedRadiatorUp', 'Radiator Oben')
  repLab('supportedRadiatorBack', 'Radiator Hinten')
  repLab('ddrgen', 'Generation')
  repLab('ramtyp', 'RAM Typ')

  return parsedItem
}

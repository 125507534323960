import React from 'react'
import { ThemeProvider } from 'styled-components'
import { CookieToast } from '../Toast/CookieToast'
import { Header } from './Header'
import { Footer } from './Footer'
import { theme, Seo } from '../utils'
import GlobalStyle from '../Styles/global'

export default function GlobalWrapper({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Seo title="ghostbuilder.io - Dein automatischer PC-Konfigurator" />
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <CookieToast />
      <Footer />
    </ThemeProvider>
  )
}

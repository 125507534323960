import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { BlogSlider } from './BlogSlider'
import fallbackImg from '../../images/image.svg'
import { callBuild, toEuro, useBudget, useQuickBuilderState } from '../helpers'
import { Button, Dots, LinkButton } from '../utils'
import { TypographyComponent } from '../utils/Typography'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const MAX_ITERATIONS = 4

export function BlogBuilderForm({ baseBudget }) {
  const [state, dispatch] = useQuickBuilderState()
  const [budgetData] = useBudget()
  const [build, setBuild] = useState([])
  const [meta, setMeta] = useState({})
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [dynamicBudget, setDynamicBudget] = useState(
    budgetData.filter((item) => item.isNormal)
  )

  useEffect(() => {
    dispatch({ type: 'budget', value: baseBudget })
  }, [])

  useEffect(() => {
    setDynamicBudget(budgetData.filter((item) => item.isNormal))
  }, [budgetData])

  async function handleSubmit(e) {
    e.preventDefault()
    setIsError(false)
    setIsLoading(true)

    fetchBuild({ budget: state.budget.value })
  }

  async function fetchBuild(options) {
    const _budgetsArr = []
    const currentBudgetIndex = dynamicBudget.findIndex(
      (item) => item.value === options.budget
    )

    for (let i = 0; i < MAX_ITERATIONS; i++) {
      const _budget = dynamicBudget[currentBudgetIndex - i]?.value
      if (_budget) {
        _budgetsArr.push(_budget)
      }
    }
    const builds = await Promise.allSettled(
      _budgetsArr.map((budget) => callBuild({ ...options, budget }))
    ).then((data) => data.map((item) => item.value))

    if (builds.every((item) => !item.build.length)) {
      setIsError(true)
      setIsLoading(false)
      return
    }

    for (let i = 0; i < MAX_ITERATIONS; i++) {
      if (!!builds[i].build?.length) {
        setIsLoading(false)
        setBuild(builds[i].build)
        setMeta(builds[i].buildMetadata)
        break
      }
    }
  }

  return (
    <Form
      className="quickBuilderForm"
      onSubmit={handleSubmit}
      aria-label="form"
      noValidate
      layout
      transition={{ duration: 0.3 }}
    >
      <Cell layout>
        {dynamicBudget && (
          <BlogSlider
            color="#2a1e4d"
            name="quickbuilder-budget"
            description="Wie viel soll investiert werden?"
            dispatch={dispatch}
            dispatchType="budget"
            value={state.budget}
            defaultValue={dynamicBudget
              .map((e) => e.value)
              .indexOf(baseBudget?.value)}
            datalist={dynamicBudget}
            min={0}
            max={dynamicBudget.length - 1}
            style={{ gridColumn: 'span 2' }}
            key={dynamicBudget.length}
          />
        )}
      </Cell>
      <Cell columns="1" justifyContent="center" layout>
        <SubmitButton
          size="medium"
          type="submit"
          value="Submit"
          whileTap={{ scale: 0.96 }}
          whileHover={{ scale: 1.04 }}
        >
          Erstelle deinen Computer
        </SubmitButton>
      </Cell>
      {/* <AnimatePresence> */}
      {isLoading && (
        <Cell
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <SmallText>
            Computer wird zusammengestellt
            <Dots color="#2a1e4d" size={2} gap={2} />
          </SmallText>
        </Cell>
      )}
      {isError && (
        <Cell
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <SmallText>Etwas lief schief. Probiere ein anderes Budget</SmallText>
        </Cell>
      )}
      {!!build?.length && (
        <>
          <BuildCell
            columns="2"
            columnGap="1rem"
            rowGap="1rem"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.25 }}
          >
            <BuildPrice>Computer für {toEuro(meta.buildPrice)}</BuildPrice>
            {build.map((item, index) => (
              <BuildContainer
                href={`${item.affiliateLink}`}
                target="_blank"
                rel="noopener noreferrer"
                key={item.name}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, textDecoration: 'none' }}
                transition={{
                  delay: index * 0.05,
                }}
              >
                <BuildImageContainer>
                  <BuildImg
                    srcSet={item.imageUrl}
                    src={fallbackImg}
                    width="64"
                    height="64"
                    alt={item.name}
                    loading="auto"
                  />
                </BuildImageContainer>
                <div>
                  <BuildType fontWeight="bold" variant="label">
                    {item.type}
                  </BuildType>
                  <BuildTitle variant="h6">{item.name}</BuildTitle>
                </div>
              </BuildContainer>
            ))}
          </BuildCell>
          <DetailButton
            size="small"
            whileTap={{ scale: 0.96 }}
            whileHover={{ scale: 1.04 }}
            style={{
              borderColor: '#2a1e4d',
              backgroundColor: `rgba(0,0,0,0)`,
            }}
            to={`/build/${meta.buildID}`}
            target="_blank"
          >
            Mehr Details
          </DetailButton>
        </>
      )}
    </Form>
  )
}

const Form = styled(motion.form)`
  padding: 14px;
  margin-block: 2rem;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  border-radius: 12px;
  color: ${({ theme }) => theme.primary};

  border-radius: 12px;
  background: #e6e2f4;

  @media (min-width: 768px) {
    padding: 32px;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
`

const Cell = styled(motion.div)`
  grid-column: span 2;
  display: grid;
  grid-template-columns: ${({ columns }) =>
    `repeat(${columns ? columns : 1}, 1fr)`};
  gap: ${({ gridGap }) => (gridGap ? `${gridGap}` : 0)};
  column-gap: ${({ columnGap }) => (columnGap ? `${columnGap}` : 0)};
  row-gap: ${({ rowGap }) => (rowGap ? `${rowGap}` : 0)};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? `${justifyContent}` : 'start'};
`

const SubmitButton = styled(Button)`
  width: 100% !important;
  border-color: #2a1e4d;
  background-color: rgba(0, 0, 0, 0);

  @media (min-width: 600px) {
    width: fit-content !important;
  }
`

const BuildCell = styled(Cell)`
  @media (max-width: 767px) {
    row-gap: 8px;
  }
`

const BuildContainer = styled(motion(OutboundLink))`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  border-radius: 8px;
  grid-column: span 2;
  text-decoration: none;
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    border-radius: 11px;
    inset: -3px;
    opacity: 0;
    background-color: rgba(205, 196, 232, 1);
    transition: opacity 0.2s ease-out;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  @media (min-width: 600px) {
    grid-column: span 1;
  }
`
const BuildImageContainer = styled(motion.div)`
  width: 48px;
  min-height: 48px;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  @media (min-width: 768px) {
    width: 64px;
    min-height: 64px;
    height: 100%;
  }
`
const BuildImg = styled.img`
  width: 85%;
  height: 85%;
  aspect-ratio: 1;
  object-fit: contain;
`

const BuildType = styled(TypographyComponent)`
  color: #382867;
  font-size: 1rem;
  line-height: 150%;
  cursor: pointer;
`
const BuildTitle = styled(TypographyComponent)`
  color: #382867;
  font-size: 1rem;
  line-height: 150%;
  font-weight: 400 !important;
  margin: 0 !important;
`

const BuildPrice = styled.div`
  grid-column: span 2;
  margin-top: 0.5rem;
`

const DetailButton = styled(LinkButton)`
  grid-column: span 2;
  font-size: 14px !important;
  border-color: #2a1e4d !important;
  color: #2a1e4d !important;
  background-color: rgba(0, 0, 0, 0) !important;

  &:hover {
    text-decoration: none !important;
  }

  @media (min-width: 768px) {
    font-size: 16px !important;
  }
`

const SmallText = styled(TypographyComponent)`
  font-size: 14px;
  display: inline-flex;
  align-items: baseline;
`

import { buildFetchSplitup, fetchPostOptions } from './fetchHelpers'

export const buildRequestOptions = ({
  budget = '2000',
  cpu = 'auto',
  gpu = 'auto',
  provider = 'cheapest',
  isCase = true,
  isPlaceholder = false,
}) => ({
  ...fetchPostOptions,
  body: JSON.stringify({
    budget: budget,
    cpu: cpu,
    gpu: gpu,
    isCase: isCase,
    isPlaceholder: isPlaceholder,
    provider: provider,
  }),
})

export const callBuild = async (options) => {
  const fetchOptions = buildRequestOptions(options)
  const response = await fetch(
    process.env.GATSBY_GHOSTBUILDER_API,
    fetchOptions
  )
  if (response.status !== 200) {
    return { build: [], buildMetadata: {} }
  } else {
    const body = await response.json()
    return buildFetchSplitup(body)
  }
}

import { fetchPostOptions } from './fetchHelpers'

export const sendFeedback = async (data) => {
  const response = await fetch(process.env.GATSBY_GHOSTBUILDER_CONTACT_API, {
    ...fetchPostOptions,
    body: JSON.stringify(data),
  })
  if (response.status !== 200) {
    return false
  }
  return true
}

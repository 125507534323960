exports.components = {
  "component---src-components-templates-blog-template-js": () => import("./../../../src/components/Templates/BlogTemplate.js" /* webpackChunkName: "component---src-components-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-build-js": () => import("./../../../src/pages/build.js" /* webpackChunkName: "component---src-pages-build-js" */),
  "component---src-pages-builder-js": () => import("./../../../src/pages/builder.js" /* webpackChunkName: "component---src-pages-builder-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-low-budget-js": () => import("./../../../src/pages/low-budget.js" /* webpackChunkName: "component---src-pages-low-budget-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-something-went-wrong-js": () => import("./../../../src/pages/something-went-wrong.js" /* webpackChunkName: "component---src-pages-something-went-wrong-js" */)
}


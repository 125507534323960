import React, { createContext, useState } from 'react'

export const ComponentContext = createContext()
export const ComponentProvider = ({ children, location }) => {
  const [contextComponents, setGlobalComponents] = useState(
    location.state.buildData
  )
  return (
    <ComponentContext.Provider
      value={{ contextComponents, setGlobalComponents }}
    >
      {children}
    </ComponentContext.Provider>
  )
}

export const MetaComponentContext = createContext()
export const MetaComponentProvider = ({ children }) => {
  const [metaComponents, setMetaComponents] = useState({
    buildId: '',
    buildPrice: 0,
    timestamp: '',
  })
  return (
    <MetaComponentContext.Provider
      value={{ metaComponents, setMetaComponents }}
    >
      {children}
    </MetaComponentContext.Provider>
  )
}

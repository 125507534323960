export const fetchGetOptions = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}
export const fetchPostOptions = {
  method: 'post',
  ...fetchGetOptions,
}

export const buildFetchSplitup = (body) => {
  let buildMetadata = {}
  body.forEach((element, idx) => {
    if (body.length === idx + 1) {
      buildMetadata = element
    }
  })
  body.pop()
  buildMetadata.buildPrice = body
    .map((element) => element.price)
    .reduce((acc, item) => acc + item)
    .toFixed(2)

  return { build: body, buildMetadata }
}
